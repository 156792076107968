<template>
  <div class="m-3 p-2 wrapper  wrapper--default">
    <div>
      <Tabs>
        <Tab
          v-for="(trust, key) in this.trustpilots"
          :key="key"
          :title="trust.fullname"
          :active="trust.active"
        >
          <b-card-text>
            <div
              class="trustpilot-widget"
              :id="`trustbox_${trust.name}`"
              data-locale="nl-NL"
              data-template-id="539adbd6dec7e10e686debee"
              :data-businessunit-id="trust.bu"
              data-style-height="750px"
              data-style-width="100%"
              data-theme="light"
              data-stars="1,2,3,4,5"
              data-review-languages="nl"
            >
              <a
                :href="`https://nl.trustpilot.com/review/${trust.url}`"
                target="_blank"
                rel="noopener"
                >Trustpilot {{ trust.fullname }}</a
              >
            </div>
          </b-card-text>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: {
    Testtab: String,
  },
  components: { Tabs, Tab },
  data: () => ({
    trustpilots: [
      {
        name: "trucks",
        fullname: "Kleyn Trucks",
        url: "kleyntrucks.com",
        bu: "51bee52800006400053ef8bb",
        active: true,
      },
      {
        name: "vans",
        fullname: "Kleyn Vans",
        url: "kleynvans.com",
        bu: "51c3bcb800006400054840bb",
        active: false,
      },
      {
        name: "tp1919",
        fullname: "Truckparts 1919",
        url: "truckparts1919.com",
        bu: "542548b700006400057a906f",
        active: false,
      },
      {
        name: "bestelbus",
        fullname: "Bestelbus.nl",
        url: "bestelbus.nl",
        bu: "581d032d0000ff0005970d7a",
        active: false,
      },
    ],
  }),
  mounted() {
    // Check if script is added
    const script = document.getElementById("trustpilot-script");
    if (!script) {
      let trustpilot = document.createElement("script");
      trustpilot.setAttribute(
        "src",
        "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      );
      trustpilot.setAttribute("async", "");
      trustpilot.id = "trustpilot-script";
      document.head.appendChild(trustpilot);
    } else {
      // force to load the trustpilot box
      this.trustpilots.forEach((trust) => {
        window.Trustpilot.loadFromElement(
          document.getElementById(`trustbox_${trust.name}`)
        );
      });
    }
  },
};
</script>
